body {
  font-family: 'Manrope', sans-serif;
}

.container {
  max-width: 1170px !important;
  padding: 0 !important;

  @media (max-width: 1100px){
    padding: 0 !important;

    &>div{
      padding-left: 25px;
      padding-right: 25px;
      box-sizing: border-box;
    }
  }

  @media (max-width: 1190px) {
    &>div {
      padding: 70px 25px;
    }
  }

  @media (max-width: 640px) {
    &>div {
      padding: 80px 46px;
    }
  }
}

.benefits-bg {
  background-color: #54586915;

  @media (max-width: 640px) {
    background-color: #fff;
  }
}

.container-faq {
  width: 100%;
  position: relative;
  background: url("/img/bg-faq.webp") no-repeat;
  background-size: cover;

  @media (max-width: 900px) {
    background: #e9ecef7f;
  }
}

.container-big{
  max-width: 1440px !important;
  padding: 0 !important;
}

p.error{
  margin: 0;
  position: absolute;
  bottom: 5px;
  font-family: 'Manrope', sans-serif;
  font-size: 13px;
  color: red;
}

span.s4s{
  color: #ff9d00;
}

.btn.MuiButton-contained{
  border-radius: 5px;
  display: block;
  background-image: linear-gradient(to right, #d99692, #d99692, #fbc1b7, #fdd4cf, #fdcbc3, #f9b4ac, #d38883, #c76b65, #da9995, #f9b7af, #fdc8c1);
  width: 204px;
  height: 52px;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: 1.8px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  cursor: pointer;
  box-shadow: none !important;

  @media (max-width: 550px){
    padding-left: 25px;
  }
  &.fixed{
    display: block;
    position: fixed;
    bottom: 20%;
    right: 0;
    @media (max-width: 900px){
      display: none !important;
    }
  }
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin: 0;
  @media (max-width: 1100px){
    font-size: 35px;
  }
  @media (max-width: 900px){
    font-size: 26px;
  }
}

.title__sliderForm{
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 2.6px;
  text-align: center;
  color: #000;
  text-transform: uppercase;
}

.company__box {
  padding-top: 35px;
}

form {
  padding-top: 48px;

  .btn.MuiButton-contained {
    border-radius: 5px;
    display: block;
    background-image: linear-gradient(to right, #d99692, #d99692, #fbc1b7, #fdd4cf, #fdcbc3, #f9b4ac, #d38883, #c76b65, #da9995, #f9b7af, #fdc8c1);
    width: 131px;
    height: 48px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: 1.8px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    cursor: pointer;
    box-shadow: none !important;
    box-sizing: border-box;
 }
}

.input-text {
  border-radius: 4.2px;
  border: solid 1.1px #d1d1d1;
  background-color: #fff;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  padding-right: 20px;
  font-family: 'Nunito Sans',sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;

  @media (max-width: 900px) {
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 640px) {
    max-height: 55px;
    height: 14.06vw;
    border-radius: 4px;
    border: solid 1px #d1d1d1;
  }

  @media (max-width: 320px) {
    height: 45px;
    border-radius: 4px;
    border: solid 1px #d1d1d1;
  }

  &:before, &:after{
    display: none;
  }

  input {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;

    @media  (max-width: 900px) {
      font-size: 14px;
    }
  }
}

.select-box{
  width: 100% !important;
  margin: 0 0 20px !important;
  label{
    font-family: 'Manrope',sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    &.MuiInputLabel-shrink{
      display: none;
    }
    @media  (max-width: 900px){
      font-size: 14px;
    }
  }

  .select{
    height: 50px;
    .MuiSelect-select{
      border-radius: 4.2px;
      border: solid 1.1px #d1d1d1;
      background-color: #fff;
      width: 100%;
      height: 50px;
      padding: 14px 20px 14px 15px;
      font-family: 'Manrope',sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #000;
      box-sizing: border-box;
      &:before, &:after{
        display: none;
      }
      @media  (max-width: 900px){
        font-size: 14px;
      }
      input{
        font-family: 'Nunito Sans',sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        @media  (max-width: 900px){
          font-size: 14px;
        }
      }
    }
  }
}

.search-wrapper:after{
  content: 'Add Services';
  position: absolute;
  top: 0;
  right: 0;
  padding: 11px 16px 11px 18px;
  border-radius: 18px;
  height: 36px;
  width: 150px;
  display: flex;
  font-family: Nunito Sans,sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: left !important;
  justify-content: flex-start;
  text-transform: none !important;
  color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  cursor: pointer;
}

.textarea {
  padding: 19px 5px 5px 15px;
  border-radius: 4px;
  border: solid 1px #d1d1d1 !important;
  background-color: #fff;
  width: 100%;
  height: 125px;

  @media  (max-width: 640px) {
    height: 32.5vw !important;
  }

  @media (max-width: 320px) {
    height: 104px !important;
  }
}

.servise-text{
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  strong{
    display: block;
  }
}
.checkbox-box{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row !important;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
  .highlightOption, .multiSelectContainer li:hover {
    background: rgb(255, 198, 0);
    color: rgb(0, 0, 0);
  }
  &>div{
    width: 100%;
  }
    &>div >div{
      padding: 0;
    }
  .checkbox-servise, .select__multi-value{
    padding: 5px 16px 5px 18px;
    border-radius: 18px;
    background-color: #ffc600;
    height: 36px;
    max-width: 150px;
    text-align: left !important;
    justify-content: flex-start;
    text-transform: none !important;
    box-sizing: border-box;
    .select__multi-value__remove{
      padding: 0;
      &:hover{
        background: none;
      }
    }
    .select__multi-value__label{
      font-family: Nunito Sans,sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
    }
    svg{
      width: 16px;
      height: 16px;
      fill: #fff;
      cursor: pointer;
    }
  }
}

.checkbox{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
  label{
    font-family: Manrope, sans-serif;
    font-size: 15.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    overflow:  visible;
    white-space: normal;
    a{
      font-weight: 600;
      color: #fff;
    }
  }
}

.phone__box.PhoneInput{
  width: 100%;
  display: inline-flex;
  margin-bottom: 0;

  select.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    width: inherit;
    border: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }

  .PhoneInputCountry{
    height: 50px;
    width: 112px;
    margin-right: 15px;
    padding-left: 15px;
    background-color: #fff;
    border-radius: 4px;
    border: solid 1px #d1d1d1;
    box-sizing: border-box;

    @media  (max-width: 640px) {
      max-height: 55px;
      height: 14.06vw;
      width: 18.12vw;
    }
  
    @media  (max-width: 320px) {
      height: 45px;
      width: 58px;
    }

    .PhoneInputCountryIcon {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      @media  (max-width: 900px) {
        margin-bottom: 0;
      }

      img, svg {
        width: 27px;
      }
    }
  }

  input {
    height: 50px;
    width: calc(100% - 100px);
    padding-left: 15px;
    font-family: 'Nunito Sans',sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
    border: solid 1px #d1d1d1;

    @media  (max-width: 640px) {
      width: 100%;
      max-height: 55px;
      height: 14.06vw;
    }
  
    @media  (max-width: 320px) {
      height: 45px;
    }
  }

  input:focus-visible {
    outline: 0;
  }

  .PhoneInputCountrySelectArrow{
    margin-left: 40px;
  }
}

.contact {
  &__box {
    width: 100%;
  }

  &__banner {
    padding: 13vw 0;
    background: url("/img/contact-bg.webp") no-repeat right top;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1120px) {
      background: url("/img/contact-bg.webp") no-repeat 65% top;
      background-size: cover;
    }

    @media (max-width: 900px) {
      justify-content: flex-start;
      background: url("/img/contact-bg.webp") no-repeat right top;
      background-size: cover;
      min-height: 180px;
      box-sizing: border-box;
    }

    @media (max-width: 640px) {
      padding: 20.31vw 0 16.56vw;
      background: url("/img/bg-contact-mobile.jpeg") no-repeat right top;
      background-size: cover;
    }

    h2 {
      width: 100%;
      max-width: 1302px;
      margin-bottom: 0;
      margin-left: 9.7vw;
      font-family: Montserrat;
      font-size: 3.5vw;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -1.03px;
      text-align: left;
      color: #f4f5f7;

      @media (max-width: 900px) {
        max-width: 40%;
        font-size: 48px;
        font-weight: 800;
        line-height: 1.17;
        letter-spacing: -0.48px;
        text-align: left;
        color: #fff;
      }

      @media (max-width: 640px) {
        max-width: 55%;
        font-size: 7.5vw;
      }

      @media (max-width: 480px) {
        max-width: 37%;
      }

      @media (max-width: 320px) {
        font-size: 24px;
      }
    }
  }

  &__help-avatar {
    min-width: unset !important;
  }

  &__content {
    width: 95%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 90px 0;
    gap: 48px;
    display: flex;
    justify-content: center;

    .form__contact {
      min-height: 450px;
      max-width: 100%;
      width: 37.7vw;

      .nameBox {
        margin-bottom: 23px;

        @media (max-width: 900px) {
          margin-bottom: 20px;
        }

        @media (max-width: 640px) {
          margin-bottom: 3.43vw;
        }
      
        @media (max-width: 320px) {
          margin-bottom: 11px;
        }
      }

      .phoneInputBox {
        margin-bottom: 23px;

        @media (max-width: 900px) {
          margin-bottom: 20px;
        }

        @media  (max-width: 640px) {
          margin-bottom: 3.43vw;
        }

        @media  (max-width: 320px) {
          margin-bottom: 11px;
        }
      }

      .emailBox {
        margin-bottom: 23px;

        @media (max-width: 900px) {
          margin-bottom: 24px;
        }

        @media (max-width: 640px) {
          margin-bottom: 4.37vw;
        }
      
        @media (max-width: 320px) {
          margin-bottom: 14px;
        }
      }

      .textareaBox {
        margin-bottom: 17px;

        @media (max-width: 900px) {
          margin-bottom: 34px;
        }

        @media (max-width: 640px) {
          margin-bottom: 5.93vw !important;
        }
      
        @media (max-width: 320px) {
          margin-bottom: 19px !important;
        }
      }

      .input-text::placeholder, input::placeholder, textarea::placeholder {
        font-family: Manrope;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000;
        opacity: 1;

        @media (max-width: 480px) {
          font-size: 14.5px;
        }
      }

      .textarea {
        height: 142px;

        .MuiOutlinedInput-root {
          padding: 19px 14px;
        }

        .MuiOutlinedInput-root, textarea {
          height: 100% !important;
        }

        fieldset {
          border: none;
        }
      }

      .error {
        position: relative;
        top: 5px;

        @media (max-width: 640px) {
          margin: 7px 0 1.5vw;
        }

        @media (max-width: 480px) {
          top: 7px;
          margin: 0 0 1.5vw;
        }
      }

      .btn.MuiButton-contained {
        width: 131px;
        height: 48px;
        padding: 0;
        text-align: center;
        background-image: linear-gradient(to left,
        #d99692, #d99692, #fbc1b7, #fdd4cf, #fdcbc3,
        #f9b4ac, #d38883, #c76b65, #da9995, #f9b7af, #fdc8c1);

        @media (max-width: 640px) {
          width: 33.75vw;
          max-height: 55px;
          height: 11.8vw;
          line-height: normal;
        }
  
        @media (max-width: 320px) {
          width: 108px;
          height: 35px;
        }
      }

      @media (max-width: 900px) {
        max-width: 550px;
        width: 100%;
        padding: 62px 58px 20px;
  
        &>div {
          width: 100%;
        }
      }

      @media (max-width: 640px) {
        min-height: unset;
        padding: 9.68vw 29px 0;
      }

      @media (max-width: 320px) {
        min-height: unset;
        padding: 31px 29px 0;
      }
    }

    @media (max-width: 900px) {
      width: 100%;
      padding: 0 0 30px;
      flex-wrap: wrap;
      gap: 0;

      &>div {
        width: 100%;
      }
    }

    @media (max-width: 640px) {
      padding-bottom: 6.25vw;
    }

    @media (max-width: 320px) {
      padding-bottom: 20px;
    }
  }

  &__help-email {
    margin: 41px 0 0 !important;

    @media (max-width: 900px) {
      margin-top: 25px !important;
    }

    @media (max-width: 640px) {
      margin-top: 4.06vw !important;
    }

    @media (max-width: 320px) {
      margin-top: 13px !important;
    }
  }

  &__list {
    max-width: 500px;
    padding: 0 !important;

    @media (max-width: 900px){
      max-width: 100%;
    }
  }

  &__help {
    &-box {
      height: min-content;
      max-width: 555px;
      width: 38.7vw;
      padding: 68px 105px 81px 66px;
      border-radius: 10px;
      background-color: #f4f5f7;
      box-sizing: border-box;

      @media (max-width: 1120px){
        margin-left: 25px;
        padding: 68px 105px 81px 46px;
      }

      @media (max-width: 900px) {
        min-height: 200px;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        padding: 70px 56px 56px;
        border-radius: unset;
      }

      @media (max-width: 640px) {
        padding: 10.93vw 28px 8.75vw;
      }

      @media (max-width: 320px) {
        min-height: 200px;
        padding: 35px 28px 28px;
      }

      h3 {
        max-height: 90px;
        max-width: 290px;
        margin: 0;
        font-family: 'Montserrat';
        font-size: 45.5px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.91px;
        text-align: left;
        color: #000;

        @media (max-width: 1190px) {
          font-size: 40px;
          max-height: unset;
          line-height: normal;
        }

        @media (max-width: 900px) {
          max-width: unset;
          font-size: 44px;
          letter-spacing: normal;
        }

        @media (max-width: 640px) {
          font-size: 6.87vw;
        }

        @media (max-width: 480px) {
          font-size: 22px;
        }
      }

      p {
        margin-bottom: 0;
        font-family: Manrope;
        font-size: 25.5px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: normal;
        text-align: left;
        color: #e27484;
        text-transform: uppercase;

        @media (max-width: 900px) {
          margin-top: 38px;
          font-size: 24px;
          line-height: 1.25;
        }

        @media (max-width: 640px) {
          margin-top: 5.93vw;
          font-size: 5vw;
        }

        @media (max-width: 320px) {
          margin-top: 19px;
          font-size: 16px;
        }
      }

      li {
        padding: 0;

        @media (max-width: 900px) {
          align-items: center;
        }
      }
    }

    &-avatar {
      height: 34px;
      width: 34px;
      margin: 0 20px 0 0;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      box-shadow: 13px 22.5px 38px 0 #0a112128;
      border-radius: 7px;

      @media (max-width: 900px) {
        height: 40px;
        width: 40px;
        min-width: 40px !important;
        padding: 12px 8px;
        box-sizing: border-box;

        img {
          width: 100%;
        }
      }

      @media (max-width: 900px) {
        height: 41px;
        width: 41px;
        margin-right: 16px;
      }

      div {
        width: 100%;
        height: 100%;
        border-radius: 0;

        img{
          height: auto;
          max-height: 100%;
          width: auto;
          max-width: 100%;
        }
      }
    }

    &-text {
      height: 50px;
      margin: 9px 0 0 !important;

      @media (max-width: 900px) {
        height: auto;
        margin: 0 !important;
      }

      & > span, p, a {
        font-family: Manrope;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.34;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        text-transform: none;
        text-decoration: none;

        @media (max-width: 1100px) {
          margin: 0;
          font-size: 18px;
          line-height: normal;
        }

        @media (max-width: 900px) {
          margin: 0;
          font-size: 22px;
          line-height: normal;
        }

        @media (max-width: 640px) {
          font-size: 18px;
          line-height: normal;
        }

        @media (max-width: 480px) {
          font-size: 14px;
        }
      }

      & > span {
        margin: 0;
        font-weight: bold;
      }
    }
  }
}

#valid, #firstName, #lastName, #valid2{
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

.congrats {
  height: 295px;
  width: 390px;
  border-style: solid;
  background-image: linear-gradient(to top, #d99692, #d99692, #fbc1b7, #fdd4cf, #fdcbc3, #f9b4ac, #d38883, #c76b65, #da9995, #f9b7af, #fdc8c1);
  border-radius: 10px;
  padding: 1px;
  margin-top: 140px;
  position: relative;

  @media (max-width: 900px){
    margin: auto;
    height: 180px;
    width: 250px;
  }

  &__img{
    height: 66px;
    width: 66px;
    background: #0d0d0d url("img/icon/tick-pink.svg") no-repeat center center;
    background-size: 46px;
    position: absolute;
    top: -33px;
    left: 150px;
    @media (max-width: 900px){
      background-color: #000;
      height: 52px;
      width: 52px;
      background-size: 32px;
      left: calc(50% - 26px);
      top: -26px;
    }
  }

  &__inner{
    background: #0d0d0d;
    height: 295px;
    width: 390px;
    padding: 48px 45px;
    box-sizing: border-box;
    border-radius: 10px;
    @media (max-width: 900px){
      height: 180px;
      width: 250px;
      padding: 40px 45px;
      background-color: #000;
    }
  }

  &.contact {
    border: none;
    padding: 3px;
    margin-top: 0;
    position: relative;

    .congrats__img{
      background:  #fff url("img/icon/tick-pink.svg") no-repeat center center;
      position: initial;
      margin: 0 auto ;
      @media (max-width: 900px){
        background-color: #fff;
      }
    }

    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 0;
      width: 25px;
      min-width: 25px;

      &:hover{
        background: none !important;
      }
    }
    .congrats__inner{
      background: #fff;

      p {
        margin: 0;
        height: 165px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        @media (max-width: 900px){
          margin-top: 25px;
          height: auto;
        }

        @media (max-width: 420px){
          margin-top: 20px;
          height: auto;
        }
      }
    }
  }

  img{
    margin: 20px auto;
    display: block;
  }

  h3{
    width: 100%;
    font-family: Montserrat, sans-serif;
    font-size: 52px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fafafa;
    margin: 0;
    padding: 0;
    @media (max-width: 900px){
      font-size: 35px;
    }
  }
  p{
    font-family: Manrope, sans-serif;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #f996a2;

    @media (max-width: 900px){
      font-size: 20px;
    }
  }
}

.contact__modal.contact {
  height: 280px;
  width: 475px;

  @media (max-width: 900px) {
    margin: auto;
    height: 220px;
    width: 340px;
  }

  @media (max-width: 420px) {
    margin: auto;
    height: 240px;
    width: 270px;
  }

  .congrats__inner {
    height: 100%;
    width: inherit;
    padding: 20px 25px 0;
    background: #fff;

    p {
      margin: 0;
      height: 165px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      @media (max-width: 900px){
        margin-top: 25px;
        height: auto;
      }

      @media (max-width: 420px){
        margin-top: 20px;
        height: auto;
      }
    }
  }
}

.css-1l9qtcc {
  @media (max-width: 900px) {
    width: auto !important;
  }
}
