.footer__box {
  width: 100%;
  padding: 63px 0 69px;
  background: '#fff';

  @media (max-width: 900px) {
    width: 100%;
    padding: 0;
    order: 1;
  }

  .container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;

    @media (max-width: 900px) {
      justify-content: center;
    }
  }

  .left {
    width: 39%;

    @media (max-width: 1190px) {
      padding: 0;
    }

    @media  (max-width: 1170px) {
      padding-left: 25px;
      box-sizing: border-box;
    }

    @media (max-width: 900px) {
      width: 100%;
      padding: 52px 51px 53px 46px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      order: 2;
      background: #54586926;
    }

    @media (max-width: 640px) {
      padding: 8.12vw 7.96vw 8.28vw 7.18vw;
    }

    @media (max-width: 320px) {
      padding: 21px 25.5px 26.5px 23px;
    }

    a {
      margin: 0 0 30px;
      display: inline-block;

      @media (max-width: 900px) {
        margin-bottom: 0;

        svg {
          width: 20px;
        }
      }
    }

    p {
      margin: 31px 0 8px;
      font-family: Nunito Sans;
      font-size: 14.5px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.03;
      letter-spacing: normal;
      text-align: left;
      color: #000;

      @media (max-width: 900px) {
        margin: 0;
        padding-bottom: 30px;
        font-size: 21px;
        line-height: 2;
        text-align: center;
      }

      @media (max-width: 640px) {
        font-size: 3.28vw;
      }
  
      @media (max-width: 320px) {
        font-size: 10.5px;
      }
    }
  }

  .right {
    width: 60%;
    padding: 0;

    @media  (max-width: 1170px) {
      padding-right: 25px;
      padding-left: 0;
      box-sizing: border-box;
    }

    @media (max-width: 900px){
      width: 100%;
      order: 1;
      padding: 57px 51px 53px 38px;
    }
    
    @media (max-width: 640px) {
      padding: 8.9vw 7.96vw 8.28vw 5.93vw;
    }

    @media (max-width: 320px) {
      padding: 28.5px 25.5px 26.5px 19px;
    }

    h3 {
      margin: 0 0 25px;
      font-family: Manrope;
      font-size: 21px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: right;
      color: #000;
      text-transform: uppercase;

      @media (max-width: 900px) {
        margin: 0 0 38px;
        font-size: 32px;
        font-weight: bold;
        line-height: 2.5;
        text-align: center;
      }

      @media (max-width: 640px) {
        margin: 0 0 5.93vw;
        font-size: 5vw;
      }
  
      @media (max-width: 320px) {
        margin: 0 0 19px;
        font-size: 16px;
      }
    }

    form {
      &>div{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        
        @media (max-width: 900px){
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          max-width: 350px;
        }

        @media (max-width: 900px){
          max-width: unset;
          justify-content: center;
        }
      }
      
      .error {
        bottom: -22px;

        @media (max-width: 900px) {
          margin-top: 12px;
          font-size: 17px;
        }

        @media (max-width: 640px) {
          position: relative;
          bottom: 0;
          margin-top: 1.87vw;
          font-size: 4vw;
        }

        @media (max-width: 320px) {
          margin-top: 6px;
          font-size: 13px;
        }
      }

      .btn {
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 1.7px;
        color: #000;

        @media (max-width: 900px){
          letter-spacing: 2.9px;
          text-align: left;
        }
      }

      .btn.MuiButton-contained {
        width: 177px;
        height: 52px;
        padding: 0;
        text-align: center;
        margin-left: 9px;

        @media (max-width: 900px) {
          width: 279px;
          height: 70px;
          margin-left: 0;
          font-size: 29px;
          line-height: normal;
        }

        @media (max-width: 640px) {
          height: 10.93vw;
          width: 44vw;
          font-size: 4.53vw;
        }

        @media (max-width: 320px) {
          height: 35px;
          font-size: 14.5px;
        }
      }

      .inputBox {
        @media (max-width: 900px) {
          width: 75%;
          margin-bottom: 30px;
        }

        @media (max-width: 640px) {
          width: 100%;
          margin-bottom: 3.75vw;
        }

        @media (max-width: 320px) {
          margin-bottom: 12px;
        }
      }

      .input-text {
        width: 377px;
        height: 53px;
        margin: 0;
        font-family: Nunito Sans;

        input {
          font-family: Nunito Sans;
          font-size: 17px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: #000;

          @media (max-width: 900px) {
            font-size: 27.5px;
          }

          @media (max-width: 640px) {
            font-size: 4.29vw;
          }
    
          @media (max-width: 320px) {
            font-size: 13.75px;
          }
        }

        input::placeholder {
          font-family: Manrope;
          font-size: 17px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: #000;
          opacity: 1;

          @media (max-width: 900px) {
            text-align: left;
            font-size: 27.5px;
          }

          @media (max-width: 640px) {
            font-size: 4.29vw;
          }
    
          @media (max-width: 320px) {
            font-size: 13.75px;
          }
        }

        @media (max-width: 900px) {
          width: 100%;
          height: 76px;
          font-size: 27.5px;
          text-align: right;
        }

        @media (max-width: 640px) {
          height: 11.87vw;
          font-size: 4.29vw;
        }
  
        @media (max-width: 320px) {
          height: 36px;
          font-size: 13.75px;
        }
      }
    }
  }

  #form_news-ok{
    text-align: right;
    display: none;
  }
  
  #form__news{
    padding: 0;
  }
}