.why {
  &__box {
    width: 100%;
    margin: 86px auto 0;
    display: flex;
    justify-content: space-between;
    position: relative;

    @media (max-width: 1240px) {
      img{
        display: none;
      }
    }

    @media (max-width: 1100px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    @media (max-width: 900px) {
      margin: 0 auto;
      width: 100%;
      flex-wrap: wrap;

      h2 {
        text-align: center;
      }

      .btn {
        display: block;
        bottom: 0;
        width: 300px;
        right: calc((100% - 310px)/2);
        text-align: center;
        padding: 10px;
      }
    }
  }

  &__img {
    display: none;
    text-indent: -9999px;
    background: url("/img/benefits-img.webp") no-repeat top center;

    @media (max-width: 1240px) {
      display: block;
      width: 50%;
      background: url("/img/benefits-img.webp") no-repeat top center;
      background-size: cover;
    }

    @media (max-width: 900px) {
      height: 650px;
    }

    @media (max-width: 760px) {
      width: 100%;
      height: 554px;
    }

    @media (max-width: 640px) {
      height: 87vw;
    }
  }

  &__text {
    width: 40%;
    max-width: 520px;
    padding: 65px 0 79px !important;
    box-sizing: border-box;
    margin-left: calc((1440px - 1170px)/2);

    @media (max-width: 1350px) {
      margin-left: 90px;
    }

    @media (max-width: 1200px) {
      width: 40%;
      margin-left: 25px;
    }

    @media (max-width: 760px) {
      width: 100%;
      max-width: 96%;
      padding: 30px 25px 35px 0 !important;
    }

    @media (max-width: 640px) {
      margin: auto;
      padding: 0 8.12vw 12.81vw 8.43vw !important;
    }

    @media (max-width: 320px) {
      padding: 0 26px 41px 27px !important;
    }

    h2 {
      text-align: left;
      color: #000;
      margin: 0 0 35px;

      @media (max-width: 900px) {
        text-align: center;
      }

      @media (max-width: 640px) {
        margin: 0 0 9.21vw;
        font-family: Montserrat;
        font-size: 8.12vw;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;
      }

      @media (max-width: 320px) {
        margin: 0 0 29.5px;
        font-size: 26px;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        position: relative;
        background: url("../../img/arrow-right.png") no-repeat left 10px;
        margin-bottom: 16px;
        padding-left: 46px;
        font-family: Manrope;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: left;
        color: #000;

        strong {
          display: block;
          font-size: 20px;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        @media (max-width: 900px) {
          font-size: 14px;

          strong {
            font-size: 14px;
          }
        }

        @media (max-width: 640px) {
          padding-left: 6.87vw;
          font-size: 4.37vw;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #000;
          background: unset;

          strong {
            font-size: 4.37vw;
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 4vw;
            height: 2.5vw;
            min-width: 13px;
            min-height: 8px;
            display: block;
            background: url("../../img/arrow-right.png") no-repeat 100% 100%;
            background-size: contain;
          }
        }

        @media (max-width: 320px) {
          padding-left: 22px;
          font-size: 14px;

          strong {
            font-size: 14px;
          }
        }
      }
    }
  }
}