.header {
  @media (max-width: 1190px){
    padding: 0 25px !important;
  }

  .logoGrid {
    h1 {
      margin: 19px 0 16px;

      a {
        display: block;
      }

      @media (max-width: 900px) {
        margin: 10px 0 16px;
      }

      @media (max-width: 420px) {
        margin: 5px 0 12px;
      }
    }
  }

  &__menu {
    @media (max-width: 900px){
      justify-content: flex-end;
    }

    &-icon {
      padding: 0 !important;
      display: none;

      @media (max-width: 900px) {
        display: block;

        svg {
          max-width: 56px;
          width: 8.7vw;
          max-height: 40px;
          height: 6.2vw;
          fill: #000;
        }
      }
    }
  }
}

.menu__box {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .menu__link {
    margin-left: 40px;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 6.67;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;

    @media (max-width: 1100px) {
      margin-left: 20px;
      font-size: 14px;
    }

    @media (max-width: 899px) {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 20px;
      display: block;
      font-size: 14px;
      line-height: 1.1;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.languageSelect {
  margin: 0 20px;

  fieldset {
    border: none;
  }
}

.logo {
  height: 93px;
  width: 251px;

  @media (max-width: 1100px) {
    height: 81px;
    width: 210px;
  }

  @media (max-width: 899px) {
    min-height: 58px;
    max-height: 116px;
    height: 18.1vw;
    min-width: 151px;
    max-width: 301px;
    width: 47vw;
  }
}
