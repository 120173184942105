.mainLayout {
  .layoutContainer {
    min-height: '100vh';
    display: 'flex';
    flex-direction: 'column';
    overflow-x: 'hidden';
  }
}

.container{
  max-width: 1170px !important;
  padding: 0 !important;

  @media (max-width: 1100px ) {
    padding: 0 !important;

    & > div {
      padding-left: 25px;
      padding-right: 25px;
      box-sizing: border-box;
    }
  }
}
