.investor{
  &__box {
    padding-top: 85px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1190px) {
      padding-bottom: 0 !important;
    }

    @media (max-width: 900px) {
      padding-bottom: 70px !important;
    }

    @media (max-width: 640px) {
      padding: 12vw 7.5vw !important;
    }

    @media (max-width: 320px) {
      padding: 38.5px 24px !important;
    }

    h2 {
      @media (max-width: 900px) {
        margin: 30px auto;
        max-width: 441px;
        display: block;
        font-size: 8.12vw;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;
      }

      @media (max-width: 640px) {
        margin: 0;
      }

      @media (max-width: 320px) {
        font-size: 26px;
      }
    }
  }

  &__pinkText {
    margin: 32px 0 0;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: 1.1px;
    text-align: center;
    text-transform: uppercase;
    color: #e17484;

    @media (max-width: 640px) {
      width: 75vw;
      margin: 9.37vw 0 0;
      font-size: 5vw;
      line-height: 1.5;
      letter-spacing: 1.6px;
      color: #f797a3;
    }

    @media (max-width: 480px) {
      width: 85vw;
    }

    @media (max-width: 320px) {
      margin: 30px 0 0;
      font-size: 16px;
    }
  }

  &__description {
    margin: 42px 0 45px;
    font-family: Manrope;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    text-align: center;
    color: #000;

    @media (max-width: 640px) {
      margin: 8.75vw 0 7.18vw;
      width: 82.8vw;
      font-size: 4.37vw;
      line-height: 1.43;
    }

    @media (max-width: 480px) {
      width: 90vw;
    }

    @media (max-width: 320px) {
      margin: 28px 0 23px;
      font-size: 14px;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 900px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 0;
    }

    @media (max-width: 640px) {
      gap: 4.21vw;
    }
  }

  &__item {
    min-height: 328px;
    width: 33%;
    max-width: 369px;
    padding: 47px 57px 55px;
    border-radius: 12.7px;
    box-sizing: border-box;
    text-align: center;

    @media (max-width: 900px) {
      width: 48%;
      max-width: 350px;
      min-height: 330px;
      height: auto;
      margin: 0 auto 12px;
      padding: 25px 15px 0;
    }

    @media (max-width: 800px) {
      width: 48%;
      max-width: 275px;
      min-height: 330px;
      height: 385px !important;
      margin: 0 auto 12px;
    }

    @media (max-width: 640px) {
      width: 100%;
      max-width: unset;
      min-height: 59.3vw;
      height: auto !important;
      margin: 0;
    }

    @media (max-width: 320px) {
      min-height: 170px;
    }

    h4 {
      margin: 30px 0 20px;
      font-family: Manrope, sans-serif;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.34;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
      color: #fff;

      @media (max-width: 900px) {
        font-size: 20px;
        line-height: 1.49;
        letter-spacing: 1.58px;
      }

      @media (max-width: 640px) {
        margin: 0 0 5.3vw;
        font-size: 4.92vw;
      }

      @media (max-width: 320px) {
        font-size: 15.75px;
      }
    }

    p {
      margin: 0;
      font-family: Manrope;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #fff;

      @media (max-width: 1150px) {
        font-size: 18px;
        line-height: 1.28;
      }

      @media (max-width: 900px) {
        font-size: 18px;
        line-height: 1.28;
      }

      @media (max-width: 640px) {
        font-size: 4.37vw;
      }

      @media (max-width: 320px) {
        font-size: 14px;
      }
    }

    &:first-of-type {
      margin-left: 0;
      padding: 47px 60px 50px 56px;
      background-color: #e17484;

      h4 {
        @media (max-width: 640px) {
          margin: 3.59vw 0 5.31vw;
        }

        @media (max-width: 320px) {
          margin: 11.5px 0 17px;
        }
      }

      svg {
        width: 80px;
        height: 90px;

        @media (max-width: 640px) {
          width: 15.31vw;
          height: 18.12vw;
        }

        @media (max-width: 320px) {
          width: 49px;
          height: 58px;
        }
      }

      @media (max-width: 1200px) {
        height: 400px;
      }

      @media (max-width: 900px) {
        height: 354px;
        margin-left: auto;
        padding: 42px 60px 45px;
      }

      @media (max-width: 640px) {
        padding: 6.56vw 11vw 7vw;
      }

      @media (max-width: 320px) {
        padding: 21px 18px 22.5px;
      }
    }

    &:nth-of-type(2) {
      padding: 55px 70px 45px;
      background-color: #f797a3;

      @media (max-width: 1200px) {
        height: 400px;
      }

      @media (max-width: 900px) {
        height: 354px;
        margin-left: auto;
        padding: 58px 45px 68px;
      }

      @media (max-width: 640px) {
        padding: 9vw 6.65vw 10.6vw;
      }

      @media (max-width: 320px) {
        padding: 29px 21px 34px;
      }

      h4 {
        margin-top: 38px;

        @media (max-width: 900px) {
          margin-top: 10px;
        }

        @media (max-width: 640px) {
          margin: 5.15vw 0 5.31vw;
        }

        @media (max-width: 320px) {
          margin: 16.5px 0 17px;
        }
      }

      svg {
        width: 79px;
        height: 79px;

        @media (max-width: 640px) {
          width: 15vw;
          height: 15vw;
        }

        @media (max-width: 320px) {
          width: 48px;
          height: 48px;
        }
      }
    }

    &:nth-of-type(3) {
      padding: 55px 42px 44px;
      background-color: #f8b3a8;

      @media (max-width: 1200px) {
        height: 400px;
      }

      @media (max-width: 900px) {
        height: auto;
        margin-left: auto;
        padding: 52px 75px 40px;
      }

      @media (max-width: 640px) {
        padding: 8.12vw 11.5vw 6.25vw;
      }

      @media (max-width: 320px) {
        padding: 26px 36.5px 20px;
      }

      h4 {
        margin-top: 37px;

        @media (max-width: 900px) {
          margin-top: 10px;
        }

        @media (max-width: 640px) {
          margin: 5.15vw 0 4.68vw;
        }

        @media (max-width: 320px) {
          margin: 16.5px 0 15px;
        }
      }

      svg {
        width: 75px;
        height: 81px;

        @media (max-width: 640px) {
          width: 14.37vw;
          height: 15.31vw;
        }

        @media (max-width: 320px) {
          width: 46px;
          height: 49px;
        }
      }
    }

    &:last-of-type {
      max-width: 100%;
      width: 100%;
      min-height: 141px;
      margin-right: 0;
      margin-top: 30px;
      padding: 28px 263px 27px 110px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 80px;
      background-color: #999999;

      @media (max-width: 1440px) {
        padding: 1.94vw 18.26vw 1.87vw 7.63vw;
      }

      @media (max-width: 1150px) {
        min-height: 150px;
        padding: 1.94vw 17.26vw 1.87vw 7.63vw;
        gap: 40px;
      }

      @media (max-width: 900px) {
        width: 48%;
        max-width: 350px;
        min-height: 330px;
        margin: 0 auto 12px;
        padding: 25px 15px 0;
        flex-direction: column;
        gap: 27px;
      }
      
      @media (max-width: 800px) {
        width: 48%;
        max-width: 275px;
        min-height: 320px;
      }

      @media (max-width: 640px) {
        width: 100%;
        max-width: unset;
        min-height: 200px;
        margin: 0;
        padding: 6.25vw 7.3vw 4.53vw;
        gap: 0;
      }

      @media (max-width: 320px) {
        padding: 20px 23px 14.5px;
      }

      .description {
        max-width: 635px;

        @media (max-width: 1150px) {
          max-width: 575px;
        }
      }

      h4 {
        margin-top: 0px;

        @media (max-width: 900px) {
          margin-top: 10px;
        }

        @media (max-width: 640px) {
          margin: 4.21vw 0 3.59vw;
        }

        @media (max-width: 320px) {
          margin: 13.5px 0 11.5px;
        }
      }

      svg {
        width: 80px;
        height: 80px;

        @media (max-width: 1150px) {
          min-width: 80px;
        }

        @media (max-width: 900px) {
          min-width: unset;
        }

        @media (max-width: 640px) {
          width: 15.31vw;
          height: 15.31vw;
        }

        @media (max-width: 320px) {
          width: 49px;
          height: 49px;
        }

        path {
          fill: #fff !important;
        }
      }
    }
  }
}