.about{
  &__box {
    padding: 60px 131px 60px 138px;
    background-color: #54586926;
    filter: alpha(opacity=15);

    @media (max-width: 1390px) {
      padding: 60px 101px 60px 108px;
    }

    @media (max-width: 1100px) {
      padding: 60px 81px 60px 88px;
    }

    @media (max-width: 900px) {
      padding: 12.18vw 6.87vw 12.81vw 6.56vw;
    }

    @media (max-width: 320px) {
      padding: 49px 22px 41px 21px;
    }

    .container {
      margin: 0 auto;
    }

    h2 {
      max-width: 1025px;
      margin: 0 auto 40px;
      display: block;
      font-family: Manrope;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #000;

      @media (max-width: 900px) {
        margin-bottom: 56px;
        padding: 0;
        font-size: 32px;
        line-height: 1.38;
      }

      @media (max-width: 640px) {
        margin-bottom: 8.75vw;
        font-size: 5vw;
      }
  
      @media (max-width: 320px) {
        margin-bottom: 28px;
        font-size: 16px;
      }
    }
  }

  &__grid {
    @media (max-width: 1390px) {
      padding: 0 !important;
      align-items: center !important;
      justify-content: center !important;
      gap: 60px;
    }

    @media (max-width: 900px) {
      gap: 38px;
    }

    @media (max-width: 640px) {
      gap: 5.93vw;
    }

    @media (max-width: 320px) {
      gap: 19px;
    }
  }

  &__item {
    height: 215px;
    padding: 36px 17px 38px 18px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    background-color: #fff;
    border-radius: 11.6px;
    box-sizing: border-box;

    @media (max-width: 900px) {
      height: auto;
      width: 100%;
      padding: 10.9vw 9vw 8.7vw 8.7vw;
      box-sizing: unset;

      p {
        max-width: 600px !important;
      }
    }

    @media (max-width: 640px) {
      padding: 7.81vw 6vw 6.71vw;
    }

    @media (max-width: 320px) {
      padding: 25px 19px 21.5px;
    }

    &:first-of-type {
      padding: 34px 34px 35px 35px;

      p {
        max-width: 300px;
      }

      h4 {
        margin-bottom: 24px;

        @media (max-width: 640px) {
          margin: 0 0 3.75vw;
        }
      }

      @media (max-width: 900px) {
        width: 100%;
        padding: 7.8vw 5.9vw 6.7vw;

        p {
          max-width: 600px;
        }
      }

      @media (max-width: 640px) {
        width: 100%;
        padding: 10.93vw 8.6vw 8.75vw;

        p {
          max-width: 600px;
        }
      }
  
      @media (max-width: 320px) {
        padding: 35px 27.5px 28px;
      }
    }

    &:last-of-type {
      padding: 37px 55px 38px;

      p {
        max-width: 262px;
      }

      h4 {
        height: 16px;
      }

      @media (max-width: 900px) {
        width: 100%;
        padding: 13.9vw 8.9vw 12.1vw;

        p {
          max-width: 600px;
        }
      }

      @media (max-width: 640px) {
        padding: 13.9vw 8.9vw 12.1vw;
      }
  
      @media (max-width: 320px) {
        padding: 44.5px 28.5px 39px;
      }
    }

    h4 {
      height: 18px;
      margin: 0 0 25px;
      font-family: Manrope, sans-serif;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: unset;
      letter-spacing: normal;
      text-align: center;
      color: #e27484;
      text-transform: uppercase;

      @media (max-width: 900px) {
        height: auto !important;
        margin: 0 0 24px;
        font-size: 32px;
        line-height: normal;
      }

      @media (max-width: 640px) {
        margin: 0 0 3.75vw;
        font-size: 5vw;
      }
  
      @media (max-width: 320px) {
        margin: 0 0 12px;
        font-size: 16px;
      }
    }

    p {
      max-width: 336px;
      height: 93px;
      margin: 0;
      font-family: Manrope;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: normal;
      text-align: center;
      color: #000;

      @media (max-width: 1100px) {
        padding: 0 15px;
      }

      @media (max-width: 900px) {
        height: auto !important;
        padding: 0;
        font-size: 28.5px;
        line-height: 1.39;
      }

      @media (max-width: 640px) {
        font-size: 4.37vw;
      }
  
      @media (max-width: 320px) {
        font-size: 14px;
      }
    }
  }
}