.roadToSuccess{
  &__box {
    margin-top: 81px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;

    @media (max-width: 1190px){
      box-sizing: border-box;
    }

    @media (max-width: 640px) {
      margin-top: 10.78vw;
    }

    @media (max-width: 640px) {
      margin-top: 34.5px;
    }

    h2 {
      font-family: Montserrat;
      font-size: 49.5px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000;

      @media (max-width: 900px) {
        margin: 30px auto;
        max-width: 495px;
        display: block;
      }

      @media (max-width: 640px) {
        margin: 0 24.37vw 9.53vw 23.75vw;
        font-size: 8.12vw;
      }

      @media (max-width: 320px) {
        margin: 0 73px 30.5px 76px;
        font-size: 26px;
      }
    }
  }

  &__pinkText {
    margin: 32px 0 0;
    font-family: Manrope;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    color: #e27484;

    @media (max-width: 640px) {
      margin: 0 11.7vw 12.43vw 11.2vw;
      font-size: 5vw;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 1.6px;
      text-align: center;
      color: #e27484;
    }

    @media (max-width: 320px) {
      margin: 0 37px 39.5px 36px;
      font-size: 16px;
    }
  }

  &__grid {
    position: relative;
    max-width: 1440px;
    padding: 0 270px 84px 280px;
    display: flex;
    justify-content: center !important;
    align-items: center;

    @media (max-width: 1440px) {
      padding: 0 18.7% 84px 19.4%;
    }

    @media (max-width: 1100px) {
      padding: 0 14.7% 84px 15.4%;
    }

    @media (max-width: 900px) {
      padding: 0;
    }

    @media (max-width: 780px) {
      justify-content: center;
    }

    .pathImgMobile {
      height: 162vw;
      margin-bottom: 13.1vw;
    }
  }

  &__unicornImg {
    position: absolute;
    left: -430px;
    bottom: 0;
    min-height: 450px;
    object-fit: cover;
    object-position: right;
    z-index: -1;

    @media (max-width: 640px) {
      display: none;
    }
  }

  &__path {
    display: grid;
    grid-template-rows: repeat(2, minmax(0, 100fr)) repeat(4, auto);
    grid-template-columns: repeat(2, auto) repeat(2, minmax(0, 100fr)) repeat(3, auto) repeat(2, minmax(0, 100fr));

    @media (max-width: 900) {
      display: none;
      visibility: hidden;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    .scouting {
      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 1;
      grid-column-end: 2;

      @media (max-width: 780px) {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }

      img {
        max-width: 71px;
        max-height: 71px;
        width: 4.9vw;
        height: 4.9vw;
      }

      span {
        bottom: -51px;
        line-height: 1.5;

        @media (max-width: 900px){
          bottom: -51px;
        }
      }
    }

    .scoutingArrow {
      margin: 0 72px 0;
      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 2;
      grid-column-end: 3;

      @media (max-width: 1440px) {
        margin: 0 5vw 0;
      }

      img {
        max-width: 62px;
        max-height: 15px;
        width: 4.3vw;
        height: 1vw;
        object-fit: contain;
      }
    }

    .firstBorder {
      position: relative;
      max-height: 91px;
      height: 6.3vw;
      margin: 0 53px 39px 0;
      grid-row-start: 2;
      grid-row-end: 4;
      grid-column-start: 4;
      grid-column-end: 6;
      border-left: solid 1.1px #000;
      border-top: solid 1.1px #000;
      border-top-left-radius: 12px;

      @media (max-width: 1440px) {
        margin: 0 3.6vw 2.7vw 0;
      }

      &::before {
        position: absolute;
        content: '';
        bottom: -5.5px;
        left: -5.5px;
        width: 11px;
        height: 11px;
        background-color: #f797a3;
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        content: '';
        top: -5.5px;
        right: -5.5px;
        width: 11px;
        height: 11px;
        background-color: #f797a3;
        border-radius: 50%;
      }
    }

    .marketplace {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 6;
      grid-column-end: 7;

      @media (max-width: 780px){
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }

      img {
        max-width: 73px;
        max-height: 68px;
        width: 5vw;
        height: 4.7vw;
      }

      span {
        bottom: -36px;
        line-height: 1.5;

        @media (max-width: 900px){
          bottom: -22px;
        }
      }
    }

    .secondBorder {
      position: relative;
      max-height: 91px;
      height: 6.3vw;
      margin: 0 0 39px 48px;
      grid-row-start: 2;
      grid-row-end: 4;
      grid-column-start: 7;
      grid-column-end: 9;
      border-right: solid 1.1px #000;
      border-top: solid 1.1px #000;
      border-top-right-radius: 12px;

      @media (max-width: 1440px) {
        margin: 0 0 2.7vw 3.3vw;
      }

      &::before {
        position: absolute;
        content: '';
        top: -5.5px;
        left: -5.5px;
        width: 11px;
        height: 11px;
        background-color: #f797a3;
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        content: '';
        bottom: -5.5px;
        right: -5.5px;
        width: 11px;
        height: 11px;
        background-color: #f797a3;
        border-radius: 50%;
      }
    }

    .assesment {
      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 3;
      grid-column-end: 5;

      @media (max-width: 780px){
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }

      img {
        max-width: 55px;
        max-height: 75px;
        width: 3.8vw;
        height: 5.2vw;
      }

      span {
        bottom: -48px;
        line-height: 1.5;

        @media (max-width: 780px){
          bottom: -48px;
        }
      }
    }

    .assesmentArrow {
      margin: 0 72px 0 85px;
      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 5;
      grid-column-end: 6;

      @media (max-width: 1440px) {
        margin: 0 5.9vw 0 5vw;
      }

      img {
        max-width: 62px;
        max-height: 15px;
        width: 4.3vw;
        height: 1vw;
        object-fit: contain;
      }
    }

    .training {
      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 6;
      grid-column-end: 7;

      @media (max-width: 780px){
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }

      img {
        max-width: 66px;
        max-height: 84px;
        width: 4.5vw;
        height: 5.8vw;
      }

      span {
        bottom: -57px;
        width: 150px;
        line-height: 1.5;

        @media (max-width: 900px){
          bottom: -57px;
        }
      }
    }

    .trainingArrow {
      margin: 0 72px 0 59px;
      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 7;
      grid-column-end: 8;

      @media (max-width: 1440px) {
        margin: 0 5vw 0 4vw;
      }

      img {
        max-width: 62px;
        max-height: 15px;
        width: 4.3vw;
        height: 1vw;
        object-fit: contain;
      }
    }

    .retreat {
      grid-row-start: 4;
      grid-row-end: 5;
      grid-column-start: 8;
      grid-column-end: 10;

      @media (max-width: 780px){
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }

      img {
        max-width: 79px;
        max-height: 78px;
        width: 5.4vw;
        height: 5.4vw;
      }

      span {
        bottom: -46px;
        line-height: 1.5;

        @media (max-width: 900px){
          bottom: -36px;
        }
      }
    }

    .thirdBorder {
      position: relative;
      max-height: 32px;
      height: 2.2vw;
      margin-top: 83px;
      grid-row-start: 5;
      grid-row-end: 6;
      grid-column-start: 4;
      grid-column-end: 9;
      border-right: solid 1.1px #000;
      border-left: solid 1.1px #000;
      border-bottom: solid 1.1px #000;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;

      @media (max-width: 1440px) {
        margin-top: 5.7vw;
      }

      &::before {
        position: absolute;
        content: '';
        top: -5.5px;
        left: -5.5px;
        width: 11px;
        height: 11px;
        background-color: #f797a3;
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        content: '';
        top: -5.5px;
        right: -5.5px;
        width: 11px;
        height: 11px;
        background-color: #f797a3;
        border-radius: 50%;
      }
    }
  }

  &__pathElement {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .imageElement {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      position: absolute;
      max-width: 150px;
      font-family: Manrope;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: 3.2px;
      text-align: center;
      text-transform: uppercase;
      color: #000;

      @media (max-width: 780px) {
        font-size: 10px;
        line-height: 1.48;
        letter-spacing: 2.4px;
      }
    }
  }
}
