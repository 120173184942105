.faq{
  &__box {
    position: relative;
    max-width: 1440px !important;
    width: 100%;
    margin: 0 auto;
    padding: 100px 169px 80px 159px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    box-sizing: border-box;

    @media (max-width: 1190px){
      padding: 95px 25px 80px;
      box-sizing: border-box;
    }

    @media (max-width: 1100px){
      &>div{
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
      }
    }

    @media (max-width: 900px) {
      padding: 76px 25px 92px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .btn{
        display: none;
      }
    }

    @media (max-width: 680px) {
      padding: 11.87vw 2.34vw 12.81vw;
    }

    @media (max-width: 320px) {
      padding: 38px 7.5px 41px;
    }
  }

  &__left {
    max-width: 290px;
    margin-right: 216px;
    text-transform: uppercase;;

    @media (max-width: 1440px){
      margin-right: 15vw;
    }

    @media (max-width: 1100px){
      max-width: 250px;
    }

    @media (max-width: 900px) {
      width: 100%;
      margin: 0;
      justify-content: center;
    }

    h2 {
      margin-bottom: 40px;
      font-family: Montserrat;
      font-size: 49.5px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000;

      @media (max-width: 680px) {
        margin-bottom: 5.93vw;
        font-size: 8.12vw;
      }
  
      @media (max-width: 320px) {
        margin-bottom: 19px;
        font-size: 26px;
      }
    }
  }
  
  &__accordion {
    max-width: 742px;
    
    @media (max-width: 1100px) {
      width: 70%;
    }

    @media (max-width: 900px) {
      width: 100%;
      font-size: 14px;

      .MuiAccordionSummary-content{
        margin: 0 !important;
      }

      p {
        margin: 36px 36px 36px 0;
      }
    }

    @media (max-width: 667px) {
      width: 100%;
    }

    @media (max-width: 640px) {
      p {
        margin: 5.62vw 5.62vw 5.62vw 0;
      }
    }

    @media (max-width: 320px) {
      p {
        margin: 18px 18px 18px 0;
      }
    }

    strong {
      max-width: 89%;
      font-family: Manrope;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: normal;
      text-align: left;
      color: #000;

      @media (max-width: 1000px) {
        font-size: 20px;
      }

      @media (max-width: 900px) {
        font-size: 28px;
        line-height: 1.71;
      }
  
      @media (max-width: 640px) {
        font-size: 4.37vw;
      }
  
      @media (max-width: 320px) {
        font-size: 14px;
      }
    }

    .MuiAccordionSummary-expandIconWrapper {
      height: 25px;
      min-width: 25px;
      width: 25px;
      -webkit-mask: url("../../img/more.svg") no-repeat 50% 50%;
      mask: url("../../img/more.svg") no-repeat 50% 50%;
      -webkit-mask-size: 'cover';
      mask-size: cover;
      background-color: #e27484;

      @media (max-width: 900px) {
        max-height: 42px;
        max-width: 42px;
        min-width: auto;
        height: 6.5vw;
        width: 6.5vw;
        background-size: 100%;
      }
  
      @media (max-width: 320px) {
        height: 21px;
        width: 21px;
      }

      &.Mui-expanded {
        height: 25px;
        min-width: 25px;
        width: 25px;
        -webkit-mask: url("../../img/minus.svg") no-repeat 50% 50%;
        mask: url("../../img/minus.svg") no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
        background-color: #e27484;
        background-size: contain;

        @media (max-width: 900px) {
          max-height: 42px;
          max-width: 42px;
          min-width: auto;
          height: 6.5vw;
          width: 6.5vw;
          background-size: 100%;
        }

        @media (max-width: 320px) {
          height: 21px;
          width: 21px;
        }
      }
    }

    .MuiAccordion-region {
      div {
        p {
          max-width: 89%;
          margin-top: 0;
          font-family: Manrope;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.47;
          letter-spacing: normal;
          text-align: left;
          color: #000;

          @media (max-width: 1000px) {
            font-size: 16px;
          }

          @media (max-width: 900px) {
            margin-bottom: 36px;
            font-size: 28px;
            line-height: 1.43;
          }

          @media (max-width: 640px) {
            margin-bottom: 5.62vw;
            font-size: 4.37vw;
          }
      
          @media (max-width: 320px) {
            margin-bottom: 18px;
            font-size: 14px;
          }
        }
      }
    }
  }
}