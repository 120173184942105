.banner{
  &__box {
    position: relative;
    width: 100%;
    overflow: hidden;
    
    @media (max-width: 1190px) {
      padding: 0 25px;
      box-sizing: border-box;
    }

    @media  (max-width: 1890px) {
      min-height: 668px;
      width: 100%;
      margin: 0;
      background: url("/img/bg-main-banner.webp") no-repeat right center;
      background-size: cover;
      img{
        display: none;
      }
    }

    @media (max-width: 1100px) {
      background: url("/img/bg-main-banner.webp") no-repeat 70% center;
    }

    @media (max-width: 900px) {
      padding-bottom: 21.8vw;
      max-height: 818px;
      background:  url("/img/banner-mobile.png") no-repeat 72% 1%;
      background-size: cover;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 260px;
        opacity: 0.46;
        mix-blend-mode: multiply;
        background-image: linear-gradient(to top, #000, #fff);

        @media  (max-width: 480px) {
          height: 130px;
        }
      }
    }

    @media  (max-width: 667px) {
      padding: 0 5.62vw 21.8vw 9.84vw;
    }

    @media  (max-width: 567px) {
      background: url("/img/banner-mobile.png") no-repeat right;
      background-size: cover;
    }

    @media  (max-width: 480px) {
      min-height: unset;
    }

    @media  (max-width: 320px) {
      padding: 0 18px 70px 32px;
    }

    img {
      min-height: 620px;
      width: 100%;
    }
  }

  &__text{
    position: absolute;
    top: 15%;
    left: calc((100vw - 1170px)/2);
    max-width: 1170px;
    width: 100%;

    p {
      font-family: Manrope;
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.64;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }

    .firstText {
      margin-top: 55px;
      font-size: 24px;
      font-weight: bold;

      @media (max-width: 900px) {
        width: 80%;
        margin: 52px 0 10px;
        font-size: 31.5px;
        line-height: normal;
      }

      @media (max-width: 640px) {
        min-width: unset;
        width: 82%;
        margin: 8.12vw 0 1.56vw;
        font-size: 4.92vw;
      }

      @media (max-width: 320px) {
        margin-top: 26px;
        font-size: 15.75px;
      }
    }

    .secondText {
      margin-bottom: 50px;

      @media (max-width: 900px) {
        width: 100%;
        margin: 0;
        font-size: 28.5px;
      }

      @media (max-width: 640px) {
        width: 100%;
        font-size: 4.34vw;
      }

      @media (max-width: 320px) {
        font-size: 13.9px;
      }
    }

    @media (min-width: 900px) {
      .btn.mobile{
        display: none !important;
      }
    }

    @media  (max-width: 1890px) {
      position: initial;
      margin:0 auto;
      padding: 121px 0 101px;
    }

    @media  (max-width: 1150px) {
      width: auto;
      margin: 0 0 0 10px;
      padding: 81px 0 61px;
    }

    @media  (max-width: 1100px) {
      margin: 0 0 0 25px;
    }

    @media (max-width: 900px) {
      margin: 0 ;
      padding: 200px 0 25.9vw;

      .btn.nomobile{
        display: none;
      }

      .btn.mobile {
        position: fixed;
        bottom: 20px;
        left: 25px;
        width: calc(100% - 50px);
        height: 54px;
        padding: 0;
        display: block;
        font-family: Montserrat;
        font-size: 29px;
        letter-spacing: 2.8px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 1.4px;
        color: #000;
        text-align: center;
        box-shadow: 0 0 12.6px 5.4px rgba(10, 17, 33, 0.17) !important;
        z-index: 100;
      }
    }

    @media  (max-width: 667px) {
      padding-top: 25.9vw;

      .btn.mobile {
        max-height: 60px;
        height: 11.56vw;
        bottom: 3.43vw;
        font-size: 4.53vw;
      }
    }

    @media  (max-width: 480px) {
      padding-bottom: 0;
    }

    @media  (max-width: 320px) {
      .btn.mobile {
        height: 37px;
        bottom: 11px;
        font-size: 14.5px;
      }
    }

    h2 {
      max-width: 765px;
      margin: 0;
      font-size: 51.5px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -1.03px;
      color: #fff;
      text-align: left;

      @media  (max-width: 1100px) {
        max-width: 700px;
      }

      @media (max-width: 900px) {
        font-size: 3rem;
        line-height: 1.17;
        letter-spacing: -0.48px;
        max-width: 85%;
        min-width: 200px;
      }

      @media (max-width: 640px) {
        font-size: 7.5vw;
      }

      @media (max-width: 320px) {
        font-size: 1.5rem;
      }
    }

    p {
      max-width: 600px;
      font-family: 'Manrope', sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #fff;

      .fs27 {
        font-size: 27.5px;
        margin-top: 10px;
        display: block;
      }

      @media  (max-width: 900px) {
        margin-top: 10px;
        font-size: 14.5px;
        line-height: 1.41;
        letter-spacing: normal;
        width: 50%;
        min-width: 270px;

        .fs27 {
          font-size: 16px;
        }
      }
    }
  }
}