.command{
  &__box {
    max-width: 1170px;
    margin: 0 auto;
    padding: 80px 0 65px;

    h2 {
      font-family: Montserrat;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000;
    }

    @media (max-width: 900px) {
      padding: 81px 40px 83px 51px;

      h2 {
        font-size: 52px;
      }
    }

    @media (max-width: 640px) {
      padding: 12.64vw 3.9vw 12.96vw 4.68vw;

      h2 {
        font-size: 8.12vw;
      }
    }

    @media (max-width: 320px) {
      padding: 40.5px 20px 41.5px 25.5px;

      h2 {
        font-size: 26px;
      }
    }
  }

  &__grid {
    margin-top: 50px;

    @media (max-width: 900px) {
      margin-top: 74px;
    }

    @media (max-width: 640px) {
      margin-top: 11.56vw;
    }

    @media (max-width: 320px) {
      margin-top: 37px;
    }
  }

  &__item {
    max-width: 212px;
    margin: 0 13px 15px;
    text-align: center;

    @media (max-width: 900px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      max-width: 550px;
      margin: 0 0 28px;

      img {
        max-width: 218px;
        width: 34vw;
        max-height: 210px;
        height: 32.8vw;
      }
    }

    @media (max-width: 640px) {
      margin-bottom: 4.37vw;

      img {
        width: 34.6vw;
        height: 32.81vw;
      }
    }

    @media (max-width: 320px) {
      margin-bottom: 14px;

      img {
        width: 109px;
        height: auto;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .person1 {
      p {
        max-width: 168px;

        @media (max-width: 900px) {
          max-width: unset;
        }
      }
    }

    .person2 {
      p {
        max-width: 137px;

        @media (max-width: 900px) {
          max-width: unset;
        }
      }
    }

    .person3 {
      p {
        max-width: 136px;

        @media (max-width: 900px) {
          max-width: unset;
        }
      }
    }

    .person4 {
      p {
        max-width: 187px;

        @media (max-width: 900px) {
          max-width: unset;
        }
      }
    }

    .text {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @media (max-width: 900px) {
        margin: 0 0 0 20px;
        align-items: flex-start;
      }

      @media (max-width: 640px) {
        margin-left: 3.12vw;
      }
  
      @media (max-width: 320px) {
        margin-left: 10px;
      }

      h4 {
        margin: 0 0 17px;
        font-family: Manrope;
        font-size: 21.5px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #e27484;

        @media (max-width: 900px) {
          margin-bottom: 23px;
          font-size: 32px;
          text-align: left;
        }

        @media (max-width: 640px) {
          margin-bottom: 3.59vw;
          font-size: 5vw;
          white-space: nowrap;
        }
    
        @media (max-width: 320px) {
          margin-bottom: 11.5px;
          font-size: 16px;
        }
      }

      p {
        margin: 0;
        font-family: Manrope;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        text-align: center;
        color: #000;

        strong{
          font-weight: 600;
          display: block;
        }

        @media (max-width: 900px) {
          font-size: 28px;
          line-height: 1.43;
          text-align: left;
        }

        @media (max-width: 640px) {
          max-width: 92%;
          font-size: 4.37vw;
        }
    
        @media (max-width: 320px) {
          font-size: 14px;
        }
      }
    }
  }
}