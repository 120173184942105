.steps {
  &__box{
    width: 100%;
    padding: 100px 0 80px;
    background: url("../../img/steps-bg.webp") no-repeat;
    background-size: cover;

    @media (max-width: 900px) {
      padding: 126px 0 150px;
      background: url("../../img/bg-steps-mobile.png") no-repeat;
      background-size: 100% 100%;
    }

    @media (max-width: 640px) {
      padding: 19.68vw 0 23.43vw;
    }

    @media (max-width: 320px) {
      padding: 63px 0 75px;
    }

    .container-big {
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      @media (max-width: 1170px) {
        justify-content: center;
      }

      @media (max-width: 900px) {
        background: url("../../img/bg-steps-mobile.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  &__text {
    width: 360px;
    margin-left: calc((1440px - 1170px)/2 + 105px);
    margin-right: 90px;

    .btn {
      background-image: linear-gradient(to left,
      #d99692, #d99692, #fbc1b7, #fdd4cf, #fdcbc3,
      #f9b4ac, #d38883, #c76b65, #da9995, #f9b7af, #fdc8c1);
    }

    @media (max-width: 1240px) {
      margin-right: 50px;
    }

    @media (max-width: 1170px) {
      margin-left: 0;
    }

    @media (max-width: 900px) {
      width: 100%;
      margin-right: 0;

      .btn{
        display: none;
      }
    }

    h2 {
      max-width: 325px;
      margin-bottom: 40px;
      font-family: Montserrat;
      font-size: 52px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      text-transform: uppercase;
      color: #fafafa;

      strong {
        font-weight: 600;
      }

      @media (max-width: 900px) {
        max-width: 90%;
        margin: 0 auto 55px;
        display: block;
        font-size: 56px;
        text-align: center;
      }

      @media (max-width: 640px) {
        margin-bottom: 8.59vw;
        font-size: 8.75vw;
      }

      @media (max-width: 320px) {
        margin-bottom: 27.5px;
        font-size: 28px;
      }
    }
  }

  &__box-list {
    width: 50%;
    max-width: 510px;

    @media (max-width: 900px) {
      width: 100%;
      max-width: 550px;
      padding: 0 46px;
    }

    @media (max-width: 640px) {
      padding: 0 7.18vw;
    }

    @media (max-width: 320px) {
      padding: 0 23px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        height: 94px;
        margin-bottom: 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 900px) {
          align-items: center;

          .number {
            height: 46px;
            margin-top: 0 !important;
            margin-right: 3px;
          }
        }

        @media (max-width: 640px) {
          height: 12.96vw;
        }

        @media (max-width: 320px) {
          height: 41.5px;
        }

        &:first-of-type {
          .number {
            height: 90px;
            margin-top: 4px;
          }

          &:before {
            top: calc(50% - 25px);
          }

          &:after {
            top: calc(50% - 20px);
          }

          svg {
            height: 37px;
            width: 39px;
          }

          @media (max-width: 900px) {
            .number {
              height: 46px;
              margin-right: 3px;
            }

            svg {
              width: 42px;
              height: 40px;
            }
          }

          @media (max-width: 640px) {
            margin-bottom: 2.96vw;

            .number {
              height: 7.18vw;
              margin-right: 0.46vw;
            }

            svg {
              width: 6.56vw;
              height: 6.25vw;
            }
          }

          @media (max-width: 320px) {
            margin-bottom: 9.5px;

            .number {
              height: 23px;
              margin-right: 1.5px;
            }

            svg {
              width: 21px;
              height: 20px;
            }
          }
        }

        &:nth-of-type(2) {
          .number {
            &:before {
              top: calc(50% - 24px);
            }
  
            &:after {
              top: calc(50% - 19px);
            }

            span {
              height: 70px;
            }
          }

          svg {
            height: 39px;
            width: 49px;

            @media (max-width: 900px) {
              width: 52px;
              height: 41px;

              .number {
                height: 46px;
                margin-right: 3px;
              }
            }
          }

          @media (max-width: 640px) {
            margin-bottom: 3.12vw;

            .number {
              height: 7.18vw;
              margin-right: 0.46vw;
            }

            svg {
              width: 8.12vw;
              height: 6.4vw;
            }
          }

          @media (max-width: 320px) {
            margin-bottom: 10px;

            .number {
              height: 23px;
              margin-right: 1.5px;
            }

            svg {
              width: 26px;
              height: 20.5px;
            }
          }
        }

        &:nth-of-type(3) {
          .number {
            &:before {
              top: calc(50% - 24px);
            }
  
            &:after {
              top: calc(50% - 19px);
            }

            span {
              height: 70px;
            }
          }

          svg {
            height: 38px;
            width: 40px;

            @media (max-width: 900px) {
              width: 42px;
              height: 40px;

              .number {
                height: 46px;
                margin-right: 3px;
              }
            }
          }

          @media (max-width: 640px) {
            margin-bottom: 3.34vw;

            .number {
              height: 7.18vw;
              margin-right: 0.46vw;
            }

            svg {
              width: 6.56vw;
              height: 6.25vw;
            }
          }

          @media (max-width: 320px) {
            margin-bottom: 11px;

            .number {
              height: 23px;
              margin-right: 1.5px;
            }

            svg {
              width: 21px;
              height: 20px;
            }
          }
        }

        &:last-of-type {
          .number {
            height: 80px;
            margin-bottom: 4px;

            &:before {
              top: calc(50% - 17px);
            }
  
            &:after {
              top: calc(50% - 12px);
            }

            span {
              height: 70px;
            }
          }

          svg {
            height: 41px;
            width: 41px;
          }

          @media (max-width: 900px) {
            svg {
              width: 44px;
              height: 44px;
            }

            .number {
              height: 46px;
            }
          }

          @media (max-width: 640px) {
            .number {
              height: 7.18vw;
              margin-right: 0.46vw;
            }

            svg {
              width: 6.87vw;
              height: 6.87vw;
            }
          }

          @media (max-width: 320px) {
            .number {
              height: 23px;
              margin-right: 1.5px;
            }

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }

        .number {
          position: relative;
          height: 94px;
          width: 90px;
          display: flex;
          justify-content: flex-start;
          font-family: Montserrat;
          font-size: 30px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: rgba(250, 250, 250, 0.95);
          border-right: 3px solid #e9ecef;

          span {
            height: 66px;
            display: flex;
            align-items: center;

            @media (max-width: 900px) {
              width: 37px;
              height: 46px;
              align-items: center;
              font-size: 32px;
              color: #fafafa;
              border: none;
  
              &:before, &:after {
                display: none;
              }
            }
  
            @media (max-width: 640px) {
              width: 5.78vw;
              height: 7.18vw;
              font-size: 5vw;
            }
  
            @media (max-width: 320px) {
              font-size: 16px;
            }
          }

          &:before {
            content: '';
            height: 24px;
            width: 24px;
            display: block;
            position: absolute;
            top: calc(50% - 25px);
            right: -12px;
            background: #000;
            z-index: 2;
          }

          &:after {
            content: '';
            height: 14px;
            width: 14px;
            position: absolute;
            top: calc(50% - 20px);
            right: -9px;
            border-radius: 50%;
            background-color: #e8e7e5;
            z-index: 3;
          }

          @media (max-width: 900px) {
            width: 37px;
            height: 46px;
            align-items: center;
            font-size: 32px;
            color: #fafafa;
            border: none;

            &:before, &:after {
              display: none;
            }
          }

          @media (max-width: 640px) {
            width: 5.78vw;
            height: 7.18vw;
            font-size: 5vw;
          }

          @media (max-width: 320px) {
            font-size: 16px;
          }
        }

        .text {
          height: 70px;
          width: 410px;
          margin-left: 20px;
          padding: 0 15px 0 36px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: Manrope;
          font-size: 22px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #000;
          border-radius: 7.4px;
          background-color: #fff;
          box-sizing: border-box;
          
          svg {
            fill: #f797a3;
          }

          @media (max-width: 900px) {
            height: 83px;
            width: 90%;
            padding: 0 20px 0 33px;
            font-size: 32.5px;
            line-height: 1.03;
            color: #111832;
          }

          @media (max-width: 640px) {
            height: 12.96vw;
            padding: 0 3.12vw 0 5.15vw;
            font-size: 5vw;
          }

          @media (max-width: 320px) {
            height: 41.5px;
            padding: 0 10px 0 16.5px;
            font-size: 16px;
          }
        }

        .firstItem {
          @media (max-width: 900px) {
            padding: 0 20px 0 33px;
          }

          @media (max-width: 640px) {
            padding: 0 3.12vw 0 5.15vw;
          }

          @media (max-width: 320px) {
            padding: 0 10px 0 16.5px;
          }

        }

        .secondItem {
          padding: 0 17px 0 34px;

          @media (max-width: 900px) {
            padding: 0 21px 0 33px;
          }

          @media (max-width: 640px) {
            padding: 0 3.28vw 0 5.15vw;
          }

          @media (max-width: 320px) {
            padding: 0 10.5px 0 16.5px;
          }
        }

        .thirdItem {
          padding: 0 16px 0 37px;

          @media (max-width: 900px) {
            padding: 0 25px 0 33px;
          }

          @media (max-width: 640px) {
            padding: 0 3.9vw 0 5.15vw;
          }

          @media (max-width: 320px) {
            padding: 0 10.5px 0 16.5px;
          }
        }

        .lastItem {
          padding: 0 14px 0 36px;

          @media (max-width: 900px) {
            padding: 0 22px 0 33px;
          }

          @media (max-width: 640px) {
            padding: 0 3.43vw 0 5.15vw;
          }

          @media (max-width: 320px) {
            padding: 0 11px 0 16.5px;
          }
        }

        &:hover{
          .number{
            &:after{
              background-color: #f996a2;
            }
          }

          .text{
            cursor: pointer;
            background-color: #f996a2;
            font-weight: bold;
            color: #fff;

            svg{
              fill: #fff;
            }
          }
        }
      }
    }
  }
}